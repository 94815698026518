// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_SUPERADMIN = '/superadmin/panel';
const ROOTS_ADMIN_AUTH = '/admin';
const ROOTS_ADMIN = '/admin/panel';
const ROOTS_CUSTOMER_AUTH = '/customer';
const ROOTS_CUSTOMER = '/customer/panel';
const ROOTS_CONTRACTOR_AUTH = '/contractor';
const ROOTS_CONTRACTOR = '/contractor/panel';
const ROOTS_TECHNICIAN_AUTH = '/technician';
const ROOTS_TECHNICIAN = '/technician/panel';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_ADMIN_AUTH = {
  root: ROOTS_ADMIN_AUTH,
  login: path(ROOTS_ADMIN_AUTH, '/login'),
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  general: {
    subadmin: path(ROOTS_ADMIN, '/subadmin'),
    addsubadmin: path(ROOTS_ADMIN, '/addsubadmin'),
    dashboard: path(ROOTS_ADMIN, '/dashboard'),
    task: path(ROOTS_ADMIN, '/task'),
    mytask: path(ROOTS_ADMIN, '/adminmytask'),
    company: path(ROOTS_ADMIN, '/company'),
    contractor: path(ROOTS_ADMIN, '/contractor'),
    contact: path(ROOTS_ADMIN, '/contact'),
    location: path(ROOTS_ADMIN, '/location'),
    assets: path(ROOTS_ADMIN, '/assets'),
    customerapproval: path(ROOTS_ADMIN, '/customerapproval'),
    contractorapproval: path(ROOTS_ADMIN, '/contractorapproval'),
    serviceentry: path(ROOTS_CUSTOMER, '/serviceentry'),
    adminjobtable: path(ROOTS_ADMIN, '/job'),
    adminjobentryform: path(ROOTS_ADMIN, '/jobentryform'),
    myresource: path(ROOTS_ADMIN, '/myresource'),
    onboarding: path(ROOTS_ADMIN, '/onboarding'),
    salesleads: path(ROOTS_ADMIN, '/salesleads'),
    reviewdataupdate: path(ROOTS_ADMIN, '/reviewdataupdate'),
    reviewuploads: path(ROOTS_ADMIN, '/reviewuploads'),
    onboardinglist: path(ROOTS_ADMIN, '/onboardinglist'),
    companyaccess: path(ROOTS_ADMIN, '/companyaccess'),
    locationaccess: path(ROOTS_ADMIN, '/locationaccess'),
  },
  user: {
    root: path(ROOTS_ADMIN, '/user'),
    profile: path(ROOTS_ADMIN, '/user/profile'),
    cards: path(ROOTS_ADMIN, '/user/cards'),
    list: path(ROOTS_ADMIN, '/user/list'),
    newUser: path(ROOTS_ADMIN, '/user/new'),
    editById: path(ROOTS_ADMIN, `/user/reece-chung/edit`),
    account: path(ROOTS_ADMIN, '/user/account')
  },
};



export const PATH_CUSTOMER = {
  root: ROOTS_CUSTOMER,
  general: {
    dashboard: path(ROOTS_CUSTOMER, '/dashboard'),
    location: path(ROOTS_CUSTOMER, '/location'),

  },
  ourcustomer: {
    root: path(ROOTS_CUSTOMER, '/ourcustomer'),
    companyinfo: path(ROOTS_CUSTOMER, '/ourcustomer/companyinfo/:customeId/:locationId'),
    usercontactinfo: path(ROOTS_CUSTOMER, '/ourcustomer/usercontactinfo/:customeId'),
    // new pages
    locationinfo: path(ROOTS_CUSTOMER, '/ourcustomer/locationinfo/:customeId'),
    cuslocationcontact: path(ROOTS_CUSTOMER, '/ourcustomer/cuslocationcontact'),
    cuslocationcontactform: path(ROOTS_CUSTOMER, '/ourcustomer/cuslocationcontactform'),
    
    elevatorinfo: path(ROOTS_CUSTOMER, '/ourcustomer/elevatorinfo'),
    fireinfo: path(ROOTS_CUSTOMER, '/ourcustomer/fireinfo'),
    keylogsinfo: path(ROOTS_CUSTOMER, '/ourcustomer/keylogsinfo'),
    firealarmasset: path(ROOTS_CUSTOMER, '/ourcustomer/firealarmasset/:customeId'),
    wetsprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/wetsprinkler/:customeId'),
    drysprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/drysprinkler/:customeId'),
    preactionsprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/preactionsprinkler/:customeId'),
    delugesprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/delugesprinkler/:customeId'),
    standpipe: path(ROOTS_CUSTOMER, '/ourcustomer/standpipe/:customeId'),
    firepump: path(ROOTS_CUSTOMER, '/ourcustomer/firepump/:customeId'),
    firehydrant: path(ROOTS_CUSTOMER, '/ourcustomer/firehydrant/:customeId'),
    kitchenhood: path(ROOTS_CUSTOMER, '/ourcustomer/kitchenhood/:customeId'),
    drychemical: path(ROOTS_CUSTOMER, '/ourcustomer/drychemical/:customeId'),
    cleanagent: path(ROOTS_CUSTOMER, '/ourcustomer/cleanagent/:customeId'),
    fireescape: path(ROOTS_CUSTOMER, '/ourcustomer/fireescape/:customeId'),
    fireextinguisher: path(ROOTS_CUSTOMER, '/ourcustomer/fireextinguisher/:customeId'),
    backflow: path(ROOTS_CUSTOMER, '/ourcustomer/backflow/:customeId'),
    othersprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/othersprinkler/:customeId'),
    companylocationquantity: path(ROOTS_CUSTOMER, '/ourcustomer/companylocationquantity'),
    deficiencyentryform: path(ROOTS_CUSTOMER, '/ourcustomer/deficiencyentryform'),
    Jobentryform: path(ROOTS_CUSTOMER, '/ourcustomer/Jobentryform'),
    locationassetinventory: path(ROOTS_CUSTOMER, '/ourcustomer/locationassetinventory'),
    serviceentryform: path(ROOTS_CUSTOMER, '/ourcustomer/serviceentryform'),
  },
  ourtask: {
    root: path(ROOTS_CUSTOMER, '/ourtask'),
    saleslead: path(ROOTS_CUSTOMER, '/ourtask/saleslead'),
    onboarding: path(ROOTS_CUSTOMER, '/ourtask/onboarding'),
    reviewdataupdate: path(ROOTS_CUSTOMER, '/ourtask/reviewdataupdate'),
    reviewupload: path(ROOTS_CUSTOMER, '/ourtask/reviewupload'),
    scheduleservice: path(ROOTS_CUSTOMER, '/ourtask/scheduleservice'),
    adminmytask: path(ROOTS_CUSTOMER, '/ourtask/adminmytask'),

  },
};

export const PATH_CONTRACTOR_AUTH = {
  root: ROOTS_CONTRACTOR_AUTH,
  login: path(ROOTS_CONTRACTOR_AUTH, '/login'),
  register: path(ROOTS_CONTRACTOR_AUTH, '/register'), 
  resetPassword: path(ROOTS_CONTRACTOR_AUTH, '/reset-password'),
  newPassword: path(ROOTS_CONTRACTOR_AUTH, '/new-password'),
  verify: path(ROOTS_CONTRACTOR_AUTH, '/verify')
};

export const PATH_CONTRACTOR = {
  root: ROOTS_CONTRACTOR,
  general: {
    dashboard: path(ROOTS_CONTRACTOR, '/dashboard'),
    // company: path(ROOTS_CONTRACTOR, '/company'),
    // cuscompany: path(ROOTS_CONTRACTOR, '/cuscompany'),
    customer: path(ROOTS_CONTRACTOR, '/customer'),
    location: path(ROOTS_CONTRACTOR, '/location'),
    task: path(ROOTS_CONTRACTOR, '/task'),

  },
 
  ourcontractor: {
    root: path(ROOTS_CONTRACTOR, '/ourcontractor'),
    companyinfo: path(ROOTS_CONTRACTOR, '/ourcontractor/companyinfo'),
    usercontactinfo: path(ROOTS_CONTRACTOR, '/ourcontractor/usercontactinfo'),
  },

  ourtask: {
    root: path(ROOTS_CONTRACTOR, '/ourtask'),
    reviewdataupdates: path(ROOTS_CONTRACTOR, '/ourtask/reviewdataupdates'),
    salelead: path(ROOTS_CONTRACTOR, '/ourtask/salelead'),

    onboarding: path(ROOTS_CONTRACTOR, '/ourtask/onboarding'),

    scheduleservice: path(ROOTS_CONTRACTOR, '/ourtask/scheduleservice'),
    reviewuploads: path(ROOTS_CONTRACTOR, '/ourtask/reviewuploads'),
    
  },
 
};

export const PATH_TECHNICIAN_AUTH = {
  root: ROOTS_TECHNICIAN_AUTH,
  login: path(ROOTS_TECHNICIAN_AUTH, '/login'),
};

export const PATH_TECHNICIAN = {
  root: ROOTS_TECHNICIAN,
  general: {
    dashboard: path(ROOTS_TECHNICIAN, '/dashboard'),
    task: path(ROOTS_TECHNICIAN, '/task'),
    technicianinfo: path(ROOTS_TECHNICIAN, '/technicianinfo'),
    job: path(ROOTS_TECHNICIAN, '/jobs'),
    jobcoming: path(ROOTS_TECHNICIAN, '/jobscomingsoon'),


    analytics: path(ROOTS_TECHNICIAN, '/analytics'),
    banking: path(ROOTS_TECHNICIAN, '/banking'),
    booking: path(ROOTS_TECHNICIAN, '/booking')
  },
};

export const PATH_SUPERADMIN = {
  root: ROOTS_SUPERADMIN,
  general: {
    dashboard: path(ROOTS_SUPERADMIN, '/dashboard'),
    company: path(ROOTS_SUPERADMIN, '/company'),
    contact: path(ROOTS_SUPERADMIN, '/contact'),
    location: path(ROOTS_SUPERADMIN, '/location'),
    credential: path(ROOTS_SUPERADMIN, '/credential'),
    assets: path(ROOTS_SUPERADMIN, '/assets'),
    customerfeedback: path(ROOTS_SUPERADMIN, '/customerfeedback'),
    customerapproval: path(ROOTS_SUPERADMIN, '/customerapproval'),
    contractorapproval: path(ROOTS_SUPERADMIN, '/contractorapproval')
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
