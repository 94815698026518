// routes
import { PATH_ADMIN } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  company: getIcon('ic_company'),
  contact: getIcon('ic_contact'),
  location: getIcon('ic_location'),
  credential: getIcon('ic_credential'),
  asset: getIcon('ic_asset'),
  feedback: getIcon('ic_feedback'),
  approval: getIcon('ic_approval'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Sub Admin', path: PATH_ADMIN.general.subadmin, icon: ICONS.contact },
      { title: 'Dashboard', path: PATH_ADMIN.general.dashboard, icon: ICONS.dashboard },
      { title: 'Task', path: PATH_ADMIN.general.task, icon: ICONS.dashboard },
      { title: 'My Customer', path: PATH_ADMIN.general.company, icon: ICONS.contact },
      { title: 'My Contractor', path: PATH_ADMIN.general.contractor, icon: ICONS.contact },
      { title: 'Service ', path: PATH_ADMIN.general.serviceentry, icon: ICONS.approval },
      { title: 'Job', path: PATH_ADMIN.general.adminjobtable, icon: ICONS.approval },
      { title: 'Customer Approval', path: PATH_ADMIN.general.customerapproval, icon: ICONS.approval },
      { title: 'Contractor Approval', path: PATH_ADMIN.general.contractorapproval, icon: ICONS.approval },
      // { title: 'Subadmin Access', path: PATH_ADMIN.general.root, icon: ICONS.credential,
      // children: [
      //   { title: 'Company Access', path: PATH_ADMIN.general.companyaccess },
      //   { title: 'Location Access', path: PATH_ADMIN.general.locationaccess },
      // ]
      // },
      
    ],
  },
];

export default navConfig;
